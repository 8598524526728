const LOCAL_STORAGE_STATE_KEY = 'LOCAL_STORAGE_STATE_KEY';

const ADD_BOARD_REQUEST = 'ADD_BOARD_REQUEST';
const ADD_BOARD_SUCCESS = 'ADD_BOARD_SUCCESS';

const ADD_COLUMN = 'ADD_COLUMN';
const RENAME_COLUMN = 'RENAME_COLUMN';
const REMOVE_COLUMN = 'REMOVE_COLUMN';

const ADD_CARD = 'ADD_CARD';
const MOVE_CARD = 'MOVE_CARD';
const RENAME_CARD = 'RENAME_CARD';
const REMOVE_CARD = 'REMOVE_CARD';
const CHANGE_CARD_DESCRIPTION = 'CHANGE_CARD_DESCRIPTION';

const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE';

const ITEM_TYPES = {
  COLUMN: 'COLUMN',
  CARD: 'CARD',
};

export {
  LOCAL_STORAGE_STATE_KEY,

  ADD_BOARD_REQUEST,
  ADD_BOARD_SUCCESS,

  ADD_COLUMN,
  RENAME_COLUMN,
  REMOVE_COLUMN,

  ADD_CARD,
  MOVE_CARD,
  RENAME_CARD,
  REMOVE_CARD,
  CHANGE_CARD_DESCRIPTION,

  ITEM_TYPES,

  SET_ERROR_MESSAGE,
  CLEAR_ERROR_MESSAGE,
};
